<template>
    <div class="editorStyle">
      <Editor  v-model="myValue" :init="init"  :disabled="column.isEdit!==0 || isView === '2'" ref="editorRef" :id="column.uuid"
      :key="timestamp"></Editor>
    </div>
</template>
<script>
import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
// 上传图片插件
import "tinymce/plugins/image";
import "tinymce/plugins/imagetools";
// 表格插件
import "tinymce/plugins/table";
// 自动识别链接插件
import "tinymce/plugins/autolink";
// 预览插件
import "tinymce/plugins/preview";
import axios from "axios";
import VueApp from "@/main.js";

export default {
  name: "rich",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    Editor
  },
  data() {
    return {
        isView: this.$route.query.openType,
        timestamp: 0,
        init: {
        language_url: "/tinymce/langs/zh-Hans.js", // 根据自己文件的位置，填写正确的路径，注意/可以直接访问到public文件
        language: "zh-Hans",
        skin_url: "/tinymce/skins/ui/oxide", // 根据自己文件的位置，填写正确的路径。路径不对会报错
        height: 200,
        plugins: "lists advlist image imagetools table autolink preview",
        // eslint-disable-next-line max-len
        toolbar: "undo redo |  formatselect | fontsizeselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table preview",
        branding: false,
        menubar: false,
        selector: `#${this.column.id}`,
        // 工作栏 icon样式
        // icons_url: "/icons/custom/icons.js",
        // icons: "custom",
        // 配置了此方法,即可手动选择图片
        images_upload_handler: (blobInfo, success, failure) => {
            // 别问我为什么要用 axios,问就是看不懂神人写的代码！！！
            const formData = new FormData();
            formData.append("storage_file", blobInfo.blob());
            const urlParam = VueApp.$store.getters.urlParam;
            console.log("upload.api", urlParam);
            // https://static.enfry.cn/Storage-api/upload.api  主板烧了 暂时用生产的
            axios.post(`${urlParam.server}?appKey=${urlParam.appKey}&token=${urlParam.token}`, formData, {
            "Content-Type": "multipart/form-data"
            }).then((res) => {
                if (res.data.code === "0" && res.data.data.fileUrl) {
                    success(res.data.data.fileUrl);
                } else {
                    failure("上传失败!");
                }
            })
            .catch((err) => {
                failure(err);
            });
        },
        resize: false
      }
    };
  },
  created() {
    console.log("3324234423", this.column);
  },
  mounted() {
    if (this.column.isEdit) this.init.toolbar = false;

     tinymce.init({
        ...this.init
    });
     const value = this.column.captionDescription.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/\\"/g, "\"").replace(/；/g, ";");
      this.column.captionDescription = value;
  },
  computed: {
     myValue: {
      get() {
      const value = this.column.captionDescription.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/\\"/g, "\"").replace(/；/g, ";");
      return value;
      },
      set(v) {
        this.column.captionDescription = v;
      }
    }
  }
};
</script>
<style lang="scss">
.editorStyle{
   .tox-statusbar{
      display: none !important;
    }
}
</style>
